













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ModelStatus extends Vue {
  @Prop({ type: Boolean, required: true }) modelLoading!: boolean;
  @Prop({ type: Boolean, required: true }) modelInitializing!: boolean;

  value: number;
  constructor() {
    super();
    this.value = 0;
  }

  get message() {
    if (this.modelLoading) {
      return "Loading model...";
    } else if (this.modelInitializing) {
      return "Loading model done. Initializing model...";
    } else {
      return "";
    }
  }
}
