








































































import MainMenu from "./components/MainMenu.vue";
import {
  DEMO_TITLES,
  DEMO_DESCRIPTIONS,
} from "./data/demo-titles";
import Component from "vue-class-component";
import Vue from "vue";

type Link = {name: string, url: string};

@Component({
  components: { MainMenu },
})
export default class App extends Vue {
  showNav: boolean;
  hasWebGL: boolean;
  links: Link[];

  constructor() {
    super();
    this.showNav = false;
    this.hasWebGL = true;
    this.links = [
        {name: 'Home', url: "/"},
        {name: 'About', url: "https://domkaukinen.com/about"},
        {name: 'Blog', url: "https://domkaukinen.com/blog"},
        {name: 'GitHub', url: "https://github.com/domerin0"},
        {name: 'Contact', url: "https://domkaukinen.com/contact"},
      ];
  }

  get currentView() {
    const path = this.$route.path;
    return path.replace(/^\//, "") || "home";
  }

  get currentTitle() {
    const title = DEMO_TITLES[this.currentView];
    if (title) {
      return title;
    } else {
      return "Machine Learning Demos";
    }
  }
  get currentDescription() {
    const description = DEMO_DESCRIPTIONS[this.currentView];
    if (description) {
      return description;
    } else {
      return "";
    }
  }
}
