







































import { Vue, Component, Prop } from "vue-property-decorator";
const DEMO_INFO = [
  {
    model: "ResNet18 Finetuned",
    title: "ResNet, finetuned",
    path: "resnet18",
  },
];

@Component
export default class MainMenu extends Vue {
  @Prop({ default: "home" }) currentView: string;
  demoInfo: Array<{ model: string; title: string; path: string }> = DEMO_INFO;
  constructor() {
    super();
    this.demoInfo = DEMO_INFO;
    console.log(this.demoInfo);
  }
}
