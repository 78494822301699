










import ndarray from "ndarray";
import ops from "ndarray-ops";
import ImageModelUI from "../common/ImageModelUI.vue";
import { Tensor } from "onnxruntime-web";
import { Vue, Component } from "vue-property-decorator";
import { RESNET_IMAGE_URLS } from "../../data/sample-image-urls";
import { resnetUtils } from "../../utils/index";
import {softmax} from "../../utils/utils-yolo/softmax";

const MODEL_FILEPATH_PROD = `/resnet18.onnx`;
const MODEL_FILEPATH_DEV = "/resnet18.onnx";

@Component({
  components: {
    ImageModelUI,
  },
})

export default class ResNet18 extends Vue {
  modelFilepath: string;
  imageUrls: Array<{ text: string; value: string }>;

  constructor() {
    super();
    this.modelFilepath =
      process.env.NODE_ENV === "production"
        ? MODEL_FILEPATH_PROD
        : MODEL_FILEPATH_DEV;
    this.imageUrls = RESNET_IMAGE_URLS;
  }

  normalize(data: Uint8ClampedArray, width: number, height:number ){
    const dataTensor = ndarray(new Float32Array(data), [width, height, 4]);
    const dataProcessedTensor = ndarray(new Float32Array(width * height * 3), [
      1,
      3,
      width,
      height,
    ]);

    // Normalize 0-255 to (-1)-1
    // ops.divseq(dataFromImage, 128.0);
    // ops.subseq(dataFromImage, 1.0);

    // ops.assign(dataProcessed.pick(0, 0, null, null), dataFromImage.pick(null, null, 2));
    // ops.assign(dataProcessed.pick(0, 1, null, null), dataFromImage.pick(null, null, 1));
    // ops.assign(dataProcessed.pick(0, 2, null, null), dataFromImage.pick(null, null, 0));
    
    ops.assign(
      dataProcessedTensor.pick(0, 0, null, null),
      dataTensor.pick(null, null, 0)
    );
    ops.assign(
      dataProcessedTensor.pick(0, 1, null, null),
      dataTensor.pick(null, null, 1)
    );
    ops.assign(
      dataProcessedTensor.pick(0, 2, null, null),
      dataTensor.pick(null, null, 2)
    );

    ops.divseq(dataProcessedTensor, 255);
    ops.subseq(dataProcessedTensor.pick(0, 0, null, null), 0.485);
    ops.subseq(dataProcessedTensor.pick(0, 1, null, null), 0.456);
    ops.subseq(dataProcessedTensor.pick(0, 2, null, null), 0.406);

    ops.divseq(dataProcessedTensor.pick(0, 0, null, null), 0.229);
    ops.divseq(dataProcessedTensor.pick(0, 1, null, null), 0.224);
    ops.divseq(dataProcessedTensor.pick(0, 2, null, null), 0.225);

    const tensor = new Tensor("float32", Float32Array.from(dataProcessedTensor.data), [
      1,
      3,
      width,
      height,
    ]);
    return tensor;
  }

  preprocess(ctx: CanvasRenderingContext2D): Tensor {
    const imageData = ctx.getImageData(
      0,
      0,
      ctx.canvas.width,
      ctx.canvas.height
    );
    const { data, width, height } = imageData;
    // data processing
    const normalized = this.normalize(data, width, height);

    // const tensor = new Tensor("float32", normalized, 
    //   [ 1, 3, 224, 224,]
    // );
    return normalized;
  }

  getPredictedClass(res: Float32Array): {} {
    if (!res || res.length === 0) {
      const empty = [];
      for (let i = 0; i < 5; i++) {
        empty.push({ name: "-", probability: 0, index: 0 });
      }
      return empty;
    }
    const tensor = new Tensor('float32', res, [1,7])
    const output = softmax(tensor, 1);
    return resnetUtils.resnetClassesTopK(output.data, 5);
  }
}
