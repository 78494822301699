
























import { Component, Vue } from "vue-property-decorator";
const DEMO_INFO = [
  {
    title: "ResNet18 fine-tuned",
    path: "resnet18",
    imagePath: require("@/assets/resnet18.png"),
  },
];

@Component
export default class HomePage extends Vue {
  demoInfo: Array<{ title: string; path: string; imagePath: string }> =
    DEMO_INFO;

  constructor() {
    super();
    this.demoInfo = DEMO_INFO;
  }
}
